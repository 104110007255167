.page_brand_bill-doctor {
  --x-site-footer--logo: url("/img/logos/bill-doctor.svg");
  --x-site-footer--logo--max-width: 96px;

  --x-site-header--logo--default: url("/img/logos/bill-doctor.svg");
  --x-site-header--logo--alternate: url("/img/logos/bill-doctor--white.svg");
  --x-site-header--logo--max-width: 96px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 140px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 88px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 128px;
  }
}

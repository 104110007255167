.page_brand_american-debt-assistance {
  --x-site-footer--logo: url("/img/logos/american-debt-assistance.svg");
  --x-site-footer--logo--max-width: 224px;

  --x-site-header--logo--default: url("/img/logos/american-debt-assistance.svg");
  --x-site-header--logo--alternate: url("/img/logos/american-debt-assistance--white.svg");
  --x-site-header--logo--max-width: 224px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 224px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 224px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 224px;
  }
}

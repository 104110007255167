.page_theme_green-mono {
  .info-section {
    &_name_cta--is-primary,
    &_name_cta--is-secondary,
    &_name_content--is-primary,
    &_name_content--is-secondary {
      .button,
      .form__button {
        @apply focus:text-text-default;
        @apply hover:text-text-default;
      }
    }

    &_name_cta--is-accent,
    &_name_content--is-accent {
      .button,
      .form__button {
        @apply bg-primary text-theme-white;
        @apply focus:bg-primary-dark;
        @apply hover:bg-primary-dark;
      }
    }
  }
}

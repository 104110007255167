.page_brand_the-debt-free-veteran {
  --x-site-footer--logo: url("/img/logos/the-debt-free-veteran.svg");
  --x-site-footer--logo--max-width: 192px;

  --x-site-header--logo--default: url("/img/logos/the-debt-free-veteran.svg");
  --x-site-header--logo--alternate: url("/img/logos/the-debt-free-veteran--white.svg");
  --x-site-header--logo--max-width: 192px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 280px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 172px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 256px;
  }
}

.page_brand_debt-free-first-responder {
  --x-site-footer--logo: url("/img/logos/debt-free-first-responder.svg");
  --x-site-footer--logo--max-width: 204px;

  --x-site-header--logo--default: url("/img/logos/debt-free-first-responder.svg");
  --x-site-header--logo--alternate: url("/img/logos/debt-free-first-responder--white.svg");
  --x-site-header--logo--max-width: 204px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 292px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 180px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 272px;
  }
}

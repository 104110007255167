.page_theme_red-mono {
  /* Colors */

  /* Primary */
  --x-color--primary: #dc2626;
  --x-color--primary-background: #fef2f2;
  --x-color--primary-dark: #450a0a;
  --x-color--primary-light: #fee2e2;
  --x-color--primary-text: #ffffff;

  /* Secondary */
  --x-color--secondary: #f87171;
  --x-color--secondary-background: #fef2f2;
  --x-color--secondary-dark: #b91c1c;
  --x-color--secondary-light: #fecaca;
  --x-color--secondary-text: #000000;

  /* Accent */
  --x-color--accent: #ef4444;
  --x-color--accent-background: #fef2f2;
  --x-color--accent-dark: #991b1b;
  --x-color--accent-light: #fca5a5;
  --x-color--accent-text: #ffffff;

  /* Neutral */
  --x-color--theme-neutral: #e5e5e5;
  --x-color--theme-neutral-background: #fafafa;
  --x-color--theme-neutral-dark: #a3a3a3;
  --x-color--theme-neutral-light: #f5f5f5;
  --x-color--theme-neutral-text: #000000;

  /* Success */
  --x-color--success: #22c55e;
  --x-color--success-dark: #14532d;
  --x-color--success-light: #f0fdf4;

  /* Outline */
  --x-focus-outline: #450a0a;

  /* Links */
  --x-link-color: #dc2626;
  --x-link-color--focus: #450a0a;
  --x-link-color--hover: #450a0a;
  --x-link-color--visited: #dc2626;

  --x-link-text-decoration: underline;

  /* Status */
  --x-color--info-default: #2563eb;
  --x-color--info-dark: #1e3a8a;
  --x-color--info-light: #dbeafe;

  --x-color--warning-default: #facc15;
  --x-color--warning-dark: #713f12;
  --x-color--warning-light: #fef9c3;

  --x-color--error-default: #ef4444;
  --x-color--error-dark: #7f1d1d;
  --x-color--error-light: #fee2e2;

  /* Text */
  --x-color--text-default: #171717;
  --x-color--text-light: #ffffff;
  --x-color--text-medium: #737373;

  /* Black and White */
  --x-color--theme-black: #000000;
  --x-color--theme-white: #ffffff;
}

.page_brand_vda {
  --x-site-footer--logo: url("/img/logos/vda.svg");
  --x-site-footer--logo--max-width: 95px;

  --x-site-header--logo--default: url("/img/logos/vda.svg");
  --x-site-header--logo--alternate: url("/img/logos/vda--white.svg");
  --x-site-header--logo--max-width: 95px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 140px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 84px;
  }

  @media (min-width: 1024px) {
    --x-site-footer--logo--max-width: 128px;

    --x-site-header--logo--max-width: 128px;
  }
}

.page_theme_magenta-lime {
  .info-section {
    &_name_cta--is-primary,
    &_name_cta--is-secondary,
    &_name_content--is-primary,
    &_name_content--is-secondary {
      .button,
      .form__button {
        @apply hover:bg-primary-light focus:text-text-default;
        @apply hover:bg-primary-light hover:text-text-default;
      }
    }

    &_name_cta--is-accent,
    &_name_content--is-accent,
    &_name_cta--is-theme-neutral-background,
    &_name_content--is-theme-neutral-background {
      .button,
      .form__button {
        @apply bg-primary text-theme-white;
        @apply focus:bg-primary-dark;
        @apply hover:bg-primary-dark;
      }
    }
  }
}

.page_brand_the-debt-free-teacher {
  --x-site-footer--logo: url("/img/logos/the-debt-free-teacher.svg");
  --x-site-footer--logo--max-width: 196px;

  --x-site-header--logo--default: url("/img/logos/the-debt-free-teacher.svg");
  --x-site-header--logo--alternate: url("/img/logos/the-debt-free-teacher--white.svg");
  --x-site-header--logo--max-width: 196px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 284px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 176px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 264px;
  }
}

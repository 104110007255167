.page_brand_american-loan-assistance {
  --x-site-footer--logo: url("/img/logos/american-loan-assistance.svg");
  --x-site-footer--logo--max-width: 196px;

  --x-site-header--logo--default: url("/img/logos/american-loan-assistance.svg");
  --x-site-header--logo--alternate: url("/img/logos/american-loan-assistance--white.svg");
  --x-site-header--logo--max-width: 196px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 280px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 172px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 260px;
  }
}

.page_brand_financially-free-nurse-ffn {
  --x-site-footer--logo: url("/img/logos/financially-free-nurse-ffn.svg");
  --x-site-footer--logo--max-width: 156px;

  --x-site-header--logo--default: url("/img/logos/financially-free-nurse-ffn.svg");
  --x-site-header--logo--alternate: url("/img/logos/financially-free-nurse-ffn--white.svg");
  --x-site-header--logo--max-width: 156px;

  @media (min-width: 640px) {
    --x-site-header--logo--max-width: 224px;
  }

  @media (min-width: 768px) {
    --x-site-header--logo--max-width: 140px;
  }

  @media (min-width: 1024px) {
    --x-site-header--logo--max-width: 208px;
  }
}
